import { graphql } from "gatsby"
// import { CalendarView } from "../components/Content/CalendarView"
import SEO, { HelmetProps } from "../components/Common/SEO"
// import HeroProducts from "../components/Hero/HeroArticlePages"

/***
 * På denne siden skal vi sette opp en oversikt over events i kalenderen.
 * TODO: Legge opp kalender slik design i Figma tilsier
 *
 */
const Calendar = ({ location, data }) => {
  // const {
  //   allContentfulEvents: { nodes: events },
  //   // contentfulGeneric: generic,
  // } = data

  // TODO: Bytte tomme strings med rett data fra contentful. Så kopiere samme løsning til alle templates
  //  const imageProps: ImageProps = {
  //  imageUrl:events.heroImage,
  // imageSecureUrl: events.heroImage,
  //   imageHigth: "",
  //   imageWitdh: "",
  //   imageAlt: "Alt fra cloudinary",
  //   imageType: "",
  // }
  // const profile: Profile = {
  //   profileFirstName: "Ola",
  //   profileLastName: "Norman",
  // }
  // const articleProps: ArticleProps = {
  //   publishTime: "",
  //   modifiedTime: "",
  //   expirationTime: "",
  //   section: "",
  //   tags: ["", ""],
  //   authors: [profile],
  // }
  const helmetProps: HelmetProps = {
    title: "Kalender",
    slug: "kalender",
    contentType: "article",
    locale: "nb_NO",
    isPublic: true,
    // articleMetaTags: articleProps,
    // images: [imageProps],
  }
  return (
    <>
      <SEO {...helmetProps} />
      <div>
        <pre>{JSON.stringify(data, null, 4)}</pre>
        <p>Kalender</p>
        <div className="relative pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8 bg-lysgronn30-300">
          {/* <HeroProducts {...generic} /> */}

          {/* <CalendarView /> */}
        </div>
      </div>
    </>
  )
}
export default Calendar

export const allContentfulEvents = graphql`
  {
    allContentfulEvents(filter: { node_locale: { eq: "nb-NO" } }) {
      edges {
        node {
          id
          body {
            childMdx {
              body
              timeToRead
            }
          }
          category {
            category
          }
          createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
          endDate(formatString: "DD.MM.YYYY hh:mm:ss")
          eventFormat
          metadataDescription
          node_locale
          slug
          startDate(formatString: "DD.MM.YYYY")
          title
          updatedAt(formatString: "DD.MM.YYYY")
        }
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    contentfulGeneric(slug: { eq: "events" }, node_locale: { eq: "nb-NO" }) {
      ingress {
        childMdx {
          body
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      heroImage {
        url
        id
        public_id
        secure_url
      }
      node_locale
      title
    }
  }
`
